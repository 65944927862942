<template>
  <div style="background: #eff1f2">
    <img class="top-img" :src="data.product.img_org" />
    <div class="goods-info">
      <div class="goods-title">
        <div class="goods-name">{{ data.product.product_title }}</div>
      </div>
    </div>
    <div class="goods-detail-page">
      <div class="desc-content">
        <div class="desc-content__title">
          <img class="img" src="../../assets/img/bx/rec-left.png" />
          <span class="desc-content__title--text">{{ search.orderCode }}</span>
          <img class="img" src="../../assets/img/bx/rec-right.png" />
        </div>
      </div>
    </div>

    <div class="set-bg" v-if="data.order.status_code == 1">
      <div class="set-item">
        <van-cell
          title="选择日期"
          :value="data.date"
          @click="data.dateShow = true"
        />
      </div>
      <div class="set-sp"></div>
      <div class="set-item">
        <van-cell
          title="选择时间"
          :value="data.time"
          @click="data.timeShow = true"
        />
      </div>
      <div class="set-sp"></div>
      <div class="set-item">
        <van-field
          readonly=""
          label="所在城市"
          style="border-radius: 1rem"
          input-align="right"
          v-model="data.address"
          @click="data.cityShow = true"
        />
      </div>
      <div class="set-sp"></div>
      <div class="set-item">
        <van-field
          required
          label="详细地址"
          style="border-radius: 1rem"
          input-align="right"
          v-model="data.detail_address"
          clearable
        />
      </div>
      <div class="set-sp"></div>
      <div class="set-item">
        <van-field
          required
          label="姓名"
          style="border-radius: 1rem"
          input-align="right"
          v-model="data.model.order_name"
          clearable
        />
      </div>
      <div class="set-sp"></div>
      <div class="set-item">
        <van-field
          required
          label="手机号"
          style="border-radius: 1rem"
          input-align="right"
          v-model="data.model.order_phone"
          clearable
        />
      </div>
      <div class="set-sp"></div>

      <div class="set-item" style="margin-top: 2rem; display: flex">
        <!-- <van-button @click="back()" class="mt fa-btn" style="border:1px solid #C90000;background-color: #fff;color:#C90000;" size="large" round type="danger">取消</van-button> -->
        <van-button
          @click="clickSave()"
          class="mt fa-btn"
          style="background-color: #c90000"
          size="large"
          round
          type="danger"
          >立即预约</van-button
        >
      </div>
    </div>

    <!------>

    <div class="set-bg" v-if="data.order.status_code != 1">
      <div class="set-item">
        <van-field
        readonly="true"
          label="预约时间"
          style="border-radius: 1rem"
          input-align="right"
          v-model="data.order.desire_time"
          clearable
        />
      </div>
      <div class="set-sp"></div>
      <div class="set-item">
        <van-field
            readonly="true"
          label="地址"
          style="border-radius: 1rem"
          input-align="right"
          v-model="data.order.order_address"
          clearable
        />
      </div>
      <div class="set-sp"></div>
      <div class="set-item">
        <van-field
        readonly="true"
          label="姓名"
          style="border-radius: 1rem"
          input-align="right"
          v-model="data.order.order_name"
          clearable
        />
      </div>
      <div class="set-sp"></div>
      <div class="set-item">
        <van-field
          readonly="true"
          label="手机号"
          style="border-radius: 1rem"
          input-align="right"
          v-model="data.order.order_phone"
          clearable
        />
      </div>
      <div class="set-sp"></div>
    </div>

    <div class="set-bg" v-if="data.order.status_code == 3||data.order.status_code == 4">
        
        <div class="set-sp"></div>
        <div class="set-item">
          <van-field
            readonly="true"
            label="服务人员"
            style="border-radius: 1rem"
            input-align="right"
            v-model="data.order.service_name"
            clearable
          />
        </div>
        <div class="set-sp"></div>
        <div class="set-item">
          <van-field
            readonly="true"
            label="手机"
            style="border-radius: 1rem"
            input-align="right"
            v-model="data.order.service_phone"
            clearable
          />
        </div>
      </div>

    <div class="service">
      <div class="service-title2">{{ getTitle1() }}</div>
      <div class="service-title3">{{ getTitle2() }}</div>
    </div>
    <div style="height: 2rem"></div>
  </div>

  <KfCard ref="kfcomp"></KfCard>
  <ShareCard ref="sharecomp"></ShareCard>

  <van-action-sheet v-model:show="data.cityShow">
    <van-area
      @cancel="data.cityShow = false"
      title="所在城市"
      :area-list="areaList"
      :columns-placeholder="['请选择', '请选择', '请选择']"
      @confirm="confirmCity"
    />
  </van-action-sheet>
  <van-calendar v-model:show="data.dateShow" @confirm="onDateConfirm" />
  <van-action-sheet v-model:show="data.timeShow">
    <van-datetime-picker
      v-model="data.currentTime"
      type="time"
      title="选择时间"
      @confirm="confirmTime"
      @cancel="data.timeShow = false"
    />
  </van-action-sheet>
</template>

<script setup>
import {} from "vant";
import {
  ref,
  reactive,
  computed,
  watch,
  markRaw,
  defineProps,
  defineExpose,
} from "vue";
import Menu from "../../components/Menu.vue";
import { useRoute, useRouter } from "vue-router";
import http from "../../common/bxhttp.js";
import {
  isJSON,
  isNull,
  showMessage,
  nullStr,
  disLabel,
  getTitle1,
  getTitle2,
} from "../../common/utils.js";
// import KfCard from '../../components/KfCard.vue'
import ShareCard from "../../components/ShareCard.vue";
import { areaList } from "@vant/area-data";

const kfcomp = ref(null);
const sharecomp = ref(null);

function showCacl() {
  router.push({ path: "/calcback" });
}

const formatDate = (date) =>
  `${date.getYear() + 1900}-${date.getMonth() + 1}-${date.getDate()}`;

function onDateConfirm(value) {
  data.dateShow = false;
  data.date = formatDate(value);
}

function confirmTime(value) {
  data.timeShow = false;
  data.time = value;
}

function showKf() {
  kfcomp.value.showKf();
}

let route = useRoute();
let router = useRouter();

let data = reactive({
  search: {
    productCode: route.query.productCode,
  },
  product: {},
  order: {},
  currentTime: "",
  model: {},
  address: "",
  detail_address: "",
  date: "",
  time: "",
  type: nullStr(localStorage.getItem("type")),
  cityShow: false,
  dateShow: false,
  timeShow: false,
});
let search = reactive({
  productCode: route.query.productCode,
  orderCode: route.query.orderCode,
});

function confirmCity(val) {
  /**
     * [{"code":"","name":""},null,null] MeEdit.vue:149
[{"code":"110000","name":"北京市"},{"code":"","name":""},null] MeEdit.vue:149
[{"code":"110000","name":"北京市"},{"code":"110100","name":"北京市"},{"code":"","name":""}] MeEdit.vue:149
[{"code":"110000","name":"北京市"},{"code":"110100","name":"北京市"},{"code":"110101","name":"东城区"}]
     */
  if (val[1] == null || val[2] == null) {
    return;
  }
  if (isNull(val[2].name)) {
    return;
  }
  data.model.province = val[0].name;
  data.model.city = val[1].name;
  data.model.area = val[2].name;
  data.address =
    nullStr(data.model.province) +
    "/" +
    nullStr(data.model.city) +
    "/" +
    nullStr(data.model.area);
  data.cityShow = false;
}

function clickSave() {
  if (isNull(data.date)) {
    showMessage("请选择日期");
    return;
  }
  if (isNull(data.time)) {
    showMessage("请选择时间");
    return;
  }
  if (isNull(data.address)) {
    showMessage("请选择省市区");
    return;
  }
  if (isNull(data.detail_address)) {
    showMessage("请填写详细地址");
    return;
  }
  if (isNull(data.model.order_name)) {
    showMessage("请填写姓名");
    return;
  }
  if (isNull(data.model.order_phone)) {
    showMessage("请填写手机号");
    return;
  }
  http
    .post("zbx/order/orderorder", {
      order_code: data.order.order_code,
      desire_time: data.date + " " + data.time,
      order_address: data.address + "/" + data.detail_address,
      order_name: data.model.order_name,
      order_phone: data.model.order_phone,
    })
    .then((res) => {
      if (res.errcode == "0") {
        //01 未匹配、03 匹配中、05 已匹配 07 专家匹配中 09 专家匹配完成
        showMessage("下单成功");
        router.push({ path: "/bxindex" });
      } else {
        showMessage(res.errmsg);
      }
    });
}

function searchOrder() {
  http
    .post("order/order/orderdetail", { orderCode: search.orderCode })
    .then((res) => {
      if (res.errcode == "0") {
        //01 未匹配、03 匹配中、05 已匹配 07 专家匹配中 09 专家匹配完成
        data.order = res.detail;
      } else {
        showMessage(res.errmsg);
      }
    });
}

function refreshPageStatus() {
  if (data.type == "I") {
    //保险用户
    if (data.order.status == "0") {
      data.fxFlag = true;
    }
  } else if (data.type == "U") {
    //普通用户
    if (data.order.status == "0") {
      data.lqFlag = true;
    }
  }
}

function searchDetail() {
  http.post("order/web/product/productdetail", data.search).then((res) => {
    if (res.errcode == "0") {
      //01 未匹配、03 匹配中、05 已匹配 07 专家匹配中 09 专家匹配完成
      data.product = res.detail;
    } else {
      showMessage(res.errmsg);
    }
  });
}
searchDetail();
searchOrder();
</script>

<style scoped lang="scss">
.top-img {
  width: 100%;
}
.goods-info {
  background-color: #fff;
  padding: 1rem 0 1rem 1rem;
}
.goods-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-top: 1rem;
}
.goods-name {
  width: 100%;
  font-weight: 500;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 1.1rem;
  word-break: break-all;
  color: #333333;
}

.goods-detail-page .desc-content {
  margin-top: 1rem;
  background-color: #fff;
  padding-bottom: 120rpx;
}

.goods-detail-page .desc-content__title {
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
}

.goods-detail-page .desc-content__title .img {
  width: 100px;
  height: 5px;
}

.goods-detail-page .desc-content__title--text {
  font-size: 1rem;
  margin: 0 0.8rem;
  font-weight: 600;
}

.goods-detail-page .desc-content__img {
  width: 100%;
  height: auto;
}
.img {
  width: 206rpx;
  height: 10rpx;
}
.detail-img {
  width: 100%;
}
.goods-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding-bottom: env(safe-area-inset-bottom);
}
.goods-bottom-con {
  padding: 16rpx;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  outline: 0;
  display: flex;
  justify-content: space-between;
}

.bottom-nav {
}
.bottom-nav-con {
  padding-top: 0.2rem;
}
.bottom-nav-title {
  margin-top: 0.1rem;
  font-size: 0.8rem;
}
.bottom-nav-img-view {
}
.bottom-nav-img {
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
  display: flex;
}
.bottom-send {
  background-color: #fa4126;
  color: #fff;
  flex: 1;
  font-size: 1.2rem;
  margin-left: 1rem;
  height: 3rem;
  border-radius: 40rpx;
  line-height: 3rem;
  text-align: center;
}
.grey-view {
  z-index: 99998;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background-color: rgb(0, 0, 0, 0.6);
}
.share-bottom {
  z-index: 99999;
  height: 10rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  // position: fixed;
  // left: 0;
  // right:0;
  // bottom: 0;
  width: 100%;
  background-color: #fff;
}
.share-bottom-con {
  padding: 1rem;
}
.share-bottom-phone {
  border: 1px solid #ddd;
  border-radius: 1rem;
  display: flex;
  height: 3rem;
  line-height: 3rem;
  padding-left: 1rem;
  justify-content: center;
  align-items: center;
}
.share-bottom-phone-input {
  flex: 1;
  border: 0px;
  border-radius: 1rem;
  height: 2.8rem;
}

.share-bottom-btn {
  margin-top: 1rem;
  border-radius: 1rem;
  background-color: #fa4126;
  color: #fff;
  flex: 1;
  font-size: 1.2rem;
  height: 3rem;
  border-radius: 40rpx;
  line-height: 3rem;
  text-align: center;
}

.shop-btn-div {
  position: fixed;
  bottom: 0px;
  left: 0rem;
  right: 0rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background: #fff;
  display: flex;
  .ml {
    margin-left: 2rem;
  }
  .shop-btn1 {
    flex: 1;
    // background:#3b6eff;
    color: #3b6eff;
    border: 1px solid #3b6eff;
    border-radius: 1.2rem;
    justify-content: center;
    align-items: center;
    display: flex;
    .divl {
      background: #3b6eff;
      height: 100%;
      width: 3rem;
      border-top-left-radius: 1.2rem;
      border-bottom-left-radius: 1.2rem;
      text-align: center;
      display: flex;
      div {
        margin: auto;
      }
    }
    img {
      width: 1.6rem;
      vertical-align: middle;
    }
    .shop-btn-name {
      flex: 1;
      margin-left: 1rem;
    }
  }
  .shop-btn2 {
    flex: 1;
    justify-content: center;
    background: #c90000;
    color: #fff;
    border-radius: 1.2rem;
    padding: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
    display: flex;
    img {
      width: 1rem;
      vertical-align: middle;
    }
    .shop-btn-name {
      margin-left: 0.4rem;
    }
  }
}
.shop-btn {
  background: #3b6eff;
  color: #fff;
  border-radius: 1rem;
  padding: 0.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  display: flex;
  img {
    width: 1rem;
    vertical-align: middle;
  }
  .shop-btn-name {
    margin-left: 0.4rem;
  }
}
.cvip13 {
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  margin-left: 0rem;
  margin-right: 0rem;
  .s1 {
    width: 1rem;
    height: 0.1rem;
    margin-top: 0.6rem;
    background: linear-gradient(90deg, #febe9a, #f95706);
  }
  .s2 {
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }
  .s3 {
    width: 1rem;
    height: 0.1rem;
    margin-top: 0.6rem;
    background: linear-gradient(90deg, #f95706, #febe9a);
  }
  color: #5617a6;
}
.pro-detail-con {
  margin: 1rem;
  .pro-tg-con {
    padding: 1rem;
    margin-top: 1rem;
    background: #fff;
    border-radius: 1rem;
    .pro-tg-con-flow-title {
      margin-top: 0.3rem;
      div {
        background: #fff8f3;
        margin-top: 0.5rem;
        color: #f86402;
        padding: 0.2rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        display: inline;
      }
    }
    .pro-tg-con-flow-desc {
      a {
        color: #3a6ff8;
      }
    }
    .pro-tg-con-desc {
      margin-top: 1rem;
      white-space: pre-line;
    }
    .pro-tg-con-div {
      display: flex;
      margin-top: 1rem;
      .pro-tg-con-item {
        flex: 1;
        background: #fff8f3;
        border-radius: 0.5rem;
        padding: 0.5rem;
        height: 8rem;
        &.ml {
          margin-left: 0.5rem;
        }
        img {
          width: 90%;
        }
        .pro-tg-con-item-desc {
          font-size: 0.8rem;
          margin-top: 0.5rem;
          a {
            color: #c71a1a;
          }
        }
      }
    }
  }

  .pro-dt-con {
    margin-top: 1rem;
    background: #fff;
    border-radius: 1rem;
    .pro-dt-item-sp {
      background: #e4e4e4;
      height: 1px;
      margin-left: 2rem;
      margin-right: 1rem;
    }
    .pro-dt-item {
      padding: 1rem;
      display: flex;
      .pro-dt-item-news {
        width: 0.6rem;
        height: 0.6rem;
        margin: 0.1rem;
        border-radius: 50%;
        background: #4d9dd0;
      }

      .pro-dt-item-desc {
        margin-left: 0.5rem;
        .pro-dt-item-time {
          color: #b9bbbb;
          font-size: 0.8rem;
        }
        .pro-dt-item-name {
          margin-top: 0.3rem;
        }
      }
    }
  }
  .pro-detail-div-title {
    display: flex;
    justify-items: center;
    .title-img {
      // width:2rem;
      // height:auto;
    }
    .title {
      line-height: 2rem;
      margin-left: 1rem;
      font-size: 1.1rem;
      color: #4d9dd0;
      &.bt {
        flex: 1;
        border-bottom: 1px solid #e4e4e4;
      }
    }
    .right-desc {
      margin-top: 0.3rem;
      color: #fff;
      font-size: 0.8rem;
      background: #4d9dd0;
      border-radius: 0.3rem;
      padding-left: 0.3rem;
      padding-top: 0.1rem;
      padding-bottom: 0.1rem;
      padding-right: 0.3rem;
    }
  }
}
.div-pro {
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 1rem;
  background: #fff;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  .match-product-item {
    padding: 1rem;
    background: #fff;
    border-radius: 1rem;

    .item-news {
      border: 30px solid #c90100;
      height: 0;
      width: 0;
      border-right-color: transparent;
      border-bottom-color: transparent;
      position: absolute;
      margin-left: -1rem;
      margin-top: -1rem;
      border-top-left-radius: 1rem;
      .item-news-title {
        margin-left: -1.3rem;
        margin-top: -1.3rem;
        color: #fff;
      }
    }

    .item-hr {
      height: 1px;
      background: #f3f3f3;
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
    }
    .item-label {
      margin-top: 1rem;
      margin-left: 2rem;
      margin-right: 2rem;
      font-size: 0.9rem;
      .item-label-div {
        margin-bottom: 0.5rem;
        display: flex;
        img {
          width: 1.2rem;
          height: 1.2rem;
        }
        div {
          margin-left: 0.4rem;
        }
      }
    }
    .item-cacl {
      display: flex;
      margin-top: 1rem;
      .item-cacl1 {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background: #eff1f2;
        border-radius: 0.3rem;
        text-align: center;
        flex: 1;
        img {
          height: 1.2rem;
        }
        div {
          font-size: 0.8rem;
          color: #0c64fb;
          margin-top: 0.2rem;
        }
      }
    }
    .item-top {
      display: flex;
      .item-top-img {
        width: 4rem;
        height: 4rem;
      }
      .item-top-desc {
        margin-left: 1rem;
        flex: 1;
        .item-back {
          margin-top: 0.5rem;
          font-size: 0.9rem;
          width: 100%;
          .item-back-desc {
            display: inline-block;
            color: #3d6fff;
            border: 1px solid #3d6fff;
            padding-left: 0.3rem;
            padding-right: 0.3rem;
            margin-right: 0.5rem;
            border-radius: 0.3rem;
            margin-bottom: 0.3rem;
          }
        }

        .item-prop {
          color: #949999;
          font-size: 0.9rem;
          width: 100%;
          .item-prop-desc {
            margin-right: 1rem;
            margin-top: 0.5rem;
            .red {
              margin-left: 0.2rem;
              color: #c90000;
            }
            .black {
              margin-left: 0.2rem;
              color: #0a1e3c;
            }
          }
        }
        .item-name {
          display: flex;
          .item-name-name {
            font-weight: 500;
          }
          .item-name-desc {
            margin-left: auto;
            font-size: 0.8rem;
            margin-left: auto;
            color: #3d6fff;
          }
        }
        .item-warn {
          background: #fcefe5;
          color: #d21616;
          padding-left: 0.3rem;
          padding-right: 0.3rem;
          border-radius: 0.2rem;
          display: inline;
        }
      }
    }
  }
}
.banner {
  width: 100%;
  height: auto;
}

.bottom-btn {
  margin: 1rem;
  display: flex;
}
.service {
  .service-title2 {
    text-align: center;
    color: #848e9d;
    line-height: 2rem;
    align-items: center;
    font-weight: 500;
    margin-left: 1rem;
    font-size: 1.1rem;
    margin-top: 4rem;
  }
  .service-title3 {
    text-align: center;
    color: #949999;
    line-height: 2rem;
    align-items: center;
    margin-left: 1rem;
    font-size: 0.9rem;
    margin-bottom: 2rem;
  }
}

.pop-city {
  height: 57%;
  background: #fff;
  position: fixed;
  bottom: 0px;
  left: 0.5rem;
  right: 0.5rem;
  z-index: 99999;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;

  .pop-ewm {
    text-align: center;
    margin-top: 1rem;
    .pop-ewm-img {
      width: 8rem;
    }
    .pop-ewm-desc {
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;
      img {
        margin-top: 0.1rem;
        width: 1rem;
        height: 1rem;
      }
      div {
        margin-left: 0.3rem;
        color: #3a6df9;
        font-size: 0.8rem;
      }
    }
  }

  .pop-steps {
    display: flex;
    .pop-steps-con {
      display: flex;
      .pop-steps-con-no {
        color: #3d6eff;
        font-size: 3rem;
      }
      .pop-steps-con-name {
        font-size: 0.8rem;
        margin-left: 0.5rem;
        .mt {
          margin-top: 0.7rem;
        }
      }
    }
    .pop-steps-con-sp {
      flex: 1;
      margin-top: 0.7rem;
      text-align: center;
    }
  }

  .pop-con {
    margin-left: 1rem;
    margin-right: 1rem;

    .mt {
      margin-top: 0.5rem;
    }
    .pop-rel {
      position: relative;
      .pop-sel {
        position: absolute;
        border: 1px solid #949999;
        border-radius: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        background: #fff;
        z-index: 999;
        right: 0px;
        left: 0px;
        .pop-sel-div {
          display: flex;
          height: 2.5rem;
          justify-content: center;
          &.bot {
            border-bottom: 1px solid #949999;
          }
          .pop-sel-div-rad {
            width: 1rem;
            height: 1rem;
            margin-top: 0.75rem;
          }
          .pop-sel-div-lab {
            line-height: 2.5rem;
            margin-left: 1rem;
          }
        }
      }
    }
    .field-div {
      border: 1px solid #949999;
      border-radius: 2rem;
      padding-left: 1rem;
      display: flex;
      align-items: center;
      .field-sp {
        width: 1px;
        background: #ececec;
        height: 1rem;
        margin-left: 0.8rem;
      }
      .field-div-label {
        margin: 0.8rem;
        color: #afb4bd;
      }
      .field-unit-sub {
        width: 8rem;
        text-align: center;
        height: 2.8rem;
        line-height: 2.8rem;
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
        color: white;
        display: flex;
        .field-sp-sub {
          width: 1px;
          background: #ececec;
          height: 1rem;
          margin-top: 0.8rem;
        }
        .dt2-sj-b {
          margin-top: 1.3rem;
          width: 100%;
          text-align: center;
          justify-content: center;
          justify-items: center;
          display: flex;
        }
        .dt2-sj {
          width: 0px;
          height: 0px;
          border: 0.4rem solid transparent;
          border-top-color: #949999;
        }
      }
      .field-unit {
        background: #929898;
        width: 8rem;
        text-align: center;
        height: 2.8rem;
        line-height: 2.8rem;
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
        color: white;
      }
      img {
        width: 1rem;
        height: 1.3rem;
        vertical-align: middle;
      }
    }
  }

  .fa-chk {
    img {
      width: 1rem;
    }
  }

  .fa-div-radio {
    margin-top: 1rem;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    .fa-radio {
      width: 0.8rem;
      height: 0.8rem;
      border: 1px solid #ddd;
      border-radius: 50%;
      .fa-sel {
        width: 0.6rem;
        height: 0.6rem;
        margin: 0.1rem;
        border-radius: 50%;
        background: #4372f6;
      }
    }
    .fa-label {
      font-size: 0.9rem;
      margin-left: 0.5rem;
      color: #0a1e3c;
    }
    .fa-label-xy {
      font-size: 0.7rem;
      margin-left: 0.5rem;
      color: #afb4bd;
      a {
        color: #3d6fff;
      }
    }
  }

  .pop-close {
    padding-left: 0.5rem;
    font-size: 1.8rem;
  }
  .pop-wtitle {
    margin-left: 3rem;
    margin-right: 3rem;
    .pop-title {
      font-size: 1rem;
      font-weight: bold;
    }
    .pop-sp {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      height: 1px;
      background: #ebebeb;
    }
    .pop-title-sub {
      color: #949999;
      margin-bottom: 0rem;
      font-size: 0.9rem;
    }

    .pop-title-label {
      margin-bottom: 1rem;
      .pop-title-label-city {
        border: 1px solid #ebebeb;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border-radius: 1rem;
        font-size: 0.8rem;
        margin-right: 0.8rem;
        float: left;
        margin-top: 0.5rem;
      }
      .clear {
        clear: both;
      }
    }
  }
  .pop-sph {
    height: 0.3rem;
    background: #ebebeb;
  }
  .pop-picker {
  }
  .pop-btn {
    margin: 1rem;
  }
}

.share-bottom {
  z-index: 99999;
  height: 10rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  // position: fixed;
  // left: 0;
  // right:0;
  // bottom: 0;
  width: 100%;
  background-color: #fff;
}
.share-bottom-con {
  padding: 1rem;
}
.share-bottom-phone {
  border: 1px solid #ddd;
  border-radius: 1rem;
  display: flex;
  height: 3rem;
  line-height: 3rem;
  padding-left: 1rem;
  justify-content: center;
  align-items: center;
}
.share-bottom-phone-input {
  flex: 1;
  border: 0px;
  border-radius: 1rem;
  height: 2.8rem;
}

.share-bottom-btn {
  margin-top: 1rem;
  border-radius: 1rem;
  background-color: #fa4126;
  color: #fff;
  flex: 1;
  font-size: 1.2rem;
  height: 3rem;
  border-radius: 40rpx;
  line-height: 3rem;
  text-align: center;
}

.shop-btn-div {
  position: fixed;
  bottom: 0px;
  left: 0rem;
  right: 0rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background: #fff;
  display: flex;
  .ml {
    margin-left: 2rem;
  }
  .shop-btn1 {
    flex: 1;
    // background:#3b6eff;
    color: #3b6eff;
    border: 1px solid #3b6eff;
    border-radius: 1.2rem;
    justify-content: center;
    align-items: center;
    display: flex;
    .divl {
      background: #3b6eff;
      height: 100%;
      width: 3rem;
      border-top-left-radius: 1.2rem;
      border-bottom-left-radius: 1.2rem;
      text-align: center;
      display: flex;
      div {
        margin: auto;
      }
    }
    img {
      width: 1.6rem;
      vertical-align: middle;
    }
    .shop-btn-name {
      flex: 1;
      margin-left: 1rem;
    }
  }
  .shop-btn2 {
    flex: 1;
    justify-content: center;
    background: #c90000;
    color: #fff;
    border-radius: 1.2rem;
    padding: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
    display: flex;
    img {
      width: 1rem;
      vertical-align: middle;
    }
    .shop-btn-name {
      margin-left: 0.4rem;
    }
  }
}
.shop-btn {
  background: #3b6eff;
  color: #fff;
  border-radius: 1rem;
  padding: 0.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  display: flex;
  img {
    width: 1rem;
    vertical-align: middle;
  }
  .shop-btn-name {
    margin-left: 0.4rem;
  }
}
.cvip13 {
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  margin-left: 0rem;
  margin-right: 0rem;
  .s1 {
    width: 1rem;
    height: 0.1rem;
    margin-top: 0.6rem;
    background: linear-gradient(90deg, #febe9a, #f95706);
  }
  .s2 {
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }
  .s3 {
    width: 1rem;
    height: 0.1rem;
    margin-top: 0.6rem;
    background: linear-gradient(90deg, #f95706, #febe9a);
  }
  color: #5617a6;
}

.set-bg {
  background: #ffffff;
  border-radius: 0.5rem;
  margin: 1rem;
  .set-sp {
    height: 1px;
    background: #ebebeb;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .set-item {
    display: flex;
    align-items: center;
    .item-img {
      width: 1.2rem;
    }
    .item-name {
      color: #061d37;
      margin-left: 1rem;
    }
    .item-right {
      margin-left: auto;
      color: #9f9f9f;
    }
  }
}
.service {
  .service-title2 {
    text-align: center;
    color: #848e9d;
    line-height: 2rem;
    align-items: center;
    font-weight: 500;
    margin-left: 1rem;
    font-size: 1.1rem;
    margin-top: 4rem;
  }
  .service-title3 {
    text-align: center;
    color: #949999;
    line-height: 2rem;
    align-items: center;
    margin-left: 1rem;
    font-size: 0.9rem;
    margin-bottom: 2rem;
  }
}

.mecon-sp {
  margin: 1rem;
  height: 1px;
  background: #ebebeb;
}
.mecon {
  margin: 1rem;
  display: flex;
  .meitem {
    padding: 1rem;
    flex: 1;
    text-align: center;
    img {
      width: 100%;
    }
    div {
      margin-top: 0.3rem;
      font-size: 0.8rem;
    }
  }
}

.metop {
  margin: 1rem;
  text-align: center;
  .metop-img-div {
    position: relative;
    width: 3rem;
    margin: auto;
    height: 3rem;
    border-radius: 50%;
    margin-top: 2rem;
    .king-div {
      position: absolute;
      margin-left: 2rem;

      .king-img {
        width: 1rem;
        position: absolute;
        bottom: -0.1rem;
      }
      .head {
        width: 3rem;
        height: 3rem;
        margin-left: -2rem;
        margin-top: -0.8rem;
        border-radius: 50%;
      }
    }
  }
  .metop-name {
    margin-left: 1rem;
    .metop-name-name {
    }
    .metop-name-lab {
      img {
        width: 5rem;
      }
    }
  }
  .metop-set {
    margin-left: auto;
    img {
      width: 1.5rem;
    }
  }
}
</style>
